(function () {
    "use strict";

    Controllers.CompanySettingsBankWrapperController = class CompanySettingsBankWrapperController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, StatsigService) {
            super($scope, $injector);
            this.__objectType = 'CompanySettingsBankWrapperController';
            this.rolloutVariant = 'pending';
            StatsigService.isGateEnabled('ng2react-company-settings-bank-details').then((enabled) => {
                this.rolloutVariant = enabled ? 'react' : 'angular';
            });
        }
    };

}()); 